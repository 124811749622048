@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
* {
  box-sizing: border-box;
  /* color: white; */
}

body {
  font-family: Poppins, sans-serif;
  margin: 0;
  background-color: #05004e;
  font-size: 1rem;
  margin-bottom: 5vh;
  height: 100%;
}

h1 {
  font-size: 2.5rem;
  color: #ffffff;
}

h2 {
  font-size: 1.5rem;
}

@media screen and (min-width: 200px) and (max-width: 480px) {
  
  html{
    font-size: 70%;
  }
}

@media screen and (min-width: 481px) and (max-width: 850px) {
  
  html{
    font-size: 95%;
  }
}
