.header {
  position: fixed;
  top: 0px;
  z-index: 999;
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #01000f;
  padding: 0 10%;
}

.logo {
  font-size: 2rem;
  color: white;
  font-weight: bold;
}

.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.header li {
  margin-right: 1.5vw;
  margin-left: 1.5vw;
}

.header a {
  text-decoration: none;
  font-size: 1.5rem;
  color: #ffffff;
}

.header a:hover,
.header a:active,
.header a.active {
  color: purple;
  font-weight: bold;
}

.badge {
  background-color: #cc2062;
  color: white;
  border-radius: 12px;
  padding: 0 1rem;
  margin-left: 0.5rem;
}

.header li:hover {
  font-weight: bold;
}

.logo_color {
  color: lightblue !important;
  margin: 1.5rem 0px;
  display: inline-block;
  font-size: 2rem !important;
}

.edit_icon {
  font-size: 1.25rem;
  color: white;
  flex-basis: 0;
  flex-grow: 0;
  position: absolute;
  right: 5vw;
  /* color: "white" !important; */
}

@media screen and (min-width: 200px) and (max-width: 480px) {
  .logo {
    font-size: 0;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    visibility: hidden;
  }
  .header a {
    font-size: 1.2rem;
  }
  .header {
    text-align: center;
    justify-content: center;
  }
  .header li {
    margin-right: 5vw;
    margin-left: 5vw;
  }
  .edit_icon {
    right: 7vw;
    left: auto;
  }
}

@media screen and (min-width: 481px) and (max-width: 1080px) {
  .logo {
    font-size: 2rem;
    color: white;
    font-weight: bold;
    visibility: hidden;
  }
  .header {
    text-align: center;
    justify-content: center;
  }
  .header li {
    margin-right: 5vw;
    margin-left: 5vw;
  }
  .edit_icon {
    right: 7vw;
    left: auto;
  }
}
