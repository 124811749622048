.item {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  width: 37.5vw;
  margin-left: 2vw;
  margin-right: 2vw;
}

.description {
  padding-left: 3vw;
  padding-right: 3vw;
  color: white;
}
.image {
  display: flex;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;

  justify-content: center;
}

.image img {
  height: auto;
  max-height: 40vh;
  max-width: 30vw;
  object-fit: cover;
  border-radius: 2vw;
  margin-top: 5vh;
}

.content {
  text-align: center;
  padding: 1rem;
}

.content p {
  margin-bottom: 0px;
}

.content h3 {
  font-size: 1.25rem;
  color: #ffffff;
}

.actions {
  padding: 1.5rem;
  text-align: center;
}

.actions button {
  font: inherit;
  cursor: pointer;
  color: rgb(255, 255, 255);
  border: none;
  background-color: rgba(114, 159, 255, 0.486);
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
}

.actions button:hover,
.actions button:active {
  background-color: #94d4ff;
  color: black;
}

.card {
  background-color: rgba(33, 169, 248, 0.301);
  margin-bottom: 5vh;
  border-radius: 2vw;
  width: 65vw;
  box-shadow: 5px 4px 4px black;
}

@media screen and (min-width: 200px) and (max-width: 480px) {
  .item {
    width: 80vw;
    margin-left: 2vw;
    margin-right: 2vw;
  }
  .card {
    background-color: rgba(33, 169, 248, 0.301);
    margin-bottom: 5vh;
    border-radius: 2vw;
    width: 80vw;
  }
  .image img {
    height: auto;
    max-height: 50vh;
    max-width: 60vw;
    object-fit: cover;
    border-radius: 2vw;
    margin-top: 5vh;
  }
}

@media screen and (min-width: 481px) and (max-width: 850px) {
  .item {
    width: 80vw;
    margin-left: 2vw;
    margin-right: 2vw;
  }
  .card {
    background-color: rgba(33, 169, 248, 0.301);
    margin-bottom: 5vh;
    border-radius: 2vw;
    width: 80vw;
  }
  .image img {
    height: auto;
    max-height: 50vh;
    max-width: 50vw;
    object-fit: cover;
    border-radius: 2vw;
    margin-top: 5vh;
  }
}
