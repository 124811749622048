.main {
  margin: 2rem auto;
  width: 100vw;
  /* max-width: 120rem; */
  margin-bottom: 10vh;
}

.footer {
  text-align: center;
  color: gray;
}

.footer_line {
  border-color: gray;
  width: 250px;
}

.icons {
  margin: 10px;
  font-size: 1.25rem;
}
